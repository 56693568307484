<template>
  <div>
    <b-card class="m-5">
      <template #header>
        <h1>{{ $t('tou.label') }}</h1>
      </template>
      <b-card-text>
        <div v-if="language === 'de'">
          Für diese Anwendung gilt die von der Universitätsleitung der FAU beschlossene <a
          href="https://www.rrze.fau.de/infocenter/rahmenbedingungen/richtlinien/benutzungsrichtlinien/"><em>Richtlinie
          für die Bereitstellung und Nutzung von Systemen und Verfahren der Informationstechnologie an der
          Friedrich-Alexander-Universität Erlangen-Nürnberg (IT-R)</em></a>.
        </div>
        <div v-else>
          The <a
          href="https://www.rrze.fau.de/infocenter/rahmenbedingungen/richtlinien/benutzungsrichtlinien/guidelines/"><em>
          IT acceptable use policy governing the provision and use of information technology systems and processes at
          Friedrich-Alexander-Universität Erlangen-Nürnberg (IT-R)</em></a> passed by the Executive Board of FAU apply
          for this application.
        </div>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import { i18nMixin } from '@/mixins/i18n.mixin'

export default {
  name: 'Tou',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  computed: {
    language () {
      return this.$root.$i18n.locale
    }
  }
}
</script>

<style scoped>
h1 {
  font-size: 1.25rem;
  font-weight: bold;
  margin: 0px;
}

h2 {
  font-size: 1.15rem;
  font-weight: bold;
}

h3 {
  font-size: 1.05rem;
  font-weight: bold;
}

h4 {
  font-size: 1rem;
  font-weight: bold;
}
</style>
